<template>
  <v-footer padless class="text-center" min-width="100%">
  <!-- <v-footer app padless class="text-center" min-width="100%"> -->
    <!-- <v-footer padless app absolute> -->
    <v-row no-gutters  text-center class="text-center">
      <v-col class="text-center" cols="12" text-center>
        <v-btn plain color="deep-purple accent-2" class="ma-0" to="/">Inicio</v-btn>
        <v-btn v-for="link in links" :key="link.title" plain color="deep-purple accent-2" class="ma-0" @click.stop="dialog = true, getDataSection(link.to)">
          {{ link.title }}
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="#7a77d0">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ section_content.title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false">
              Cerrar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-divider></v-divider>

          <v-container>
            <v-card>
              <v-card-text>
                <div v-html="section_content.contentData"></div>
              </v-card-text>
            </v-card>
          </v-container>
        <!-- {{ section_content }} -->
      </v-card>
    </v-dialog>
  </v-footer>
</template>

<script>

import {db} from '../main'

export default {
  name: 'Footer',
  data: () => ({
    // isLoading: true,
    dialog: false,
    section_content: [],
    links: [
      { title: 'Metodología', icon: 'mdi-school', to: 'metodologia' },
      { title: 'Acerca de', icon: 'mdi-help-box', to: 'acerca' },
      { title: 'Política y manejo de datos', icon: 'mdi-book-open-page-variant', to: 'politica' },
    ],
   
  }),
  methods: {
    async getDataSection(sec) {
      try {
        const doc = await db.collection('contenidos').doc(sec).get()
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          console.log('Document data:', doc.data());
          this.section_content = doc.data()
        }
      } catch (error) {
        console.log(error)
      }
    },
  }
}
</script>
